.highlight-language {
  color: #dd4339 !important;
}

.mobile-overlay{
  position: absolute;
  top: 80px;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  background-color: #40403E;
  display: flex;
  padding-left: 40px;
  border-radius: 30px;
  .mobile-menus-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    .mobile-menus {
      margin-top: 30px;
      margin-bottom: 80px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-size: 30px;
      &.language-menus {
        flex-direction: row;
        .separator {
          color: white;
        }
        div:first-of-type:after {
          content: "";
          width: 1px;
          height: 15px;
          display: inline-block;
          position: absolute;
          background: #fff;
          bottom: 3px;
          right: 0;
        }
      }
      a{
        color: white;
      }
    }
  }


}