html {
  height: 100%;
  width: 100%;
}

.App {
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  width: 100%;
  position: relative;
  &.mobile-menu-open {
    overflow-y: hidden;
  }
}

a {
  text-decoration: none !important;
}