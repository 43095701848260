@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Regular/Montserrat-Regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Montserrat-Regular/Montserrat-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/Montserrat-Regular/Montserrat-Regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/Montserrat-Regular/Montserrat-Regular.woff') format('woff'), /* Pretty Modern Browsers */
    url('../fonts/Montserrat-Regular/Montserrat-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
    url('../fonts/Montserrat-Regular/Montserrat-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
    font-weight: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold/Montserrat-Bold.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Montserrat-Bold/Montserrat-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/Montserrat-Bold/Montserrat-Bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/Montserrat-Bold/Montserrat-Bold.woff') format('woff'), /* Pretty Modern Browsers */
    url('../fonts/Montserrat-Bold/Montserrat-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
    url('../fonts/Montserrat-Bold/Montserrat-Bold.svg#svgFontName') format('svg'); /* Legacy iOS */
    font-weight: bold;
}



html, body{
    height:100%;
    width: 100%;
}
body {
    position: relative;
}
#homepage {
    font-family: 'Montserrat', sans-serif;
}

#homepage nav {
    position: absolute;
    z-index: 1;
    top: 85px;
    width: 100%;
    background: transparent;
    border: 0;
    box-shadow: none;
}
#homepage .navbar .container-fluid {
    max-width: 1725px;
}

#homepage .navbar .navbar-header {
    float: left;
}

#homepage .navbar .nav.navbar-nav {
    float: right;
}

.navbar-default .navbar-nav a {
    font-size: 16px;
}

.navbar-default .navbar-nav>li>a {
    cursor: pointer;
    font-size: 16px;
    color: #fff;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.open>a {
    box-shadow: none;
    background: transparent;
    color: #dd4339;
}

.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover {
    background-color: transparent;
    color: #dd4339;
}

.homepage {
    color: white;
    text-transform: uppercase;
    padding: 90px;
    height: 100vh;
    font-family: 'Montserrat', sans-serif;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position:center bottom;
    background-attachment:fixed;
}
#homepage .container-fluid{
    height: 100%;
    max-width: 1450px;
    padding: 0;
    position: relative;
}

#homepage .homepage-description {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.homepage a{
    color: white;
}
.homepage .scroll-down {
    text-transform: none;
    font-size: 18px;
}
.homepage .scroll-down:hover {
    text-decoration: none;
}
.homepage .scroll-down .fa-angle-down {
    font-size: 30px;
    vertical-align: middle;
    color: #dd4339;
}
.homepage h1{
    max-width: 700px;
    font-weight: normal;
    font-size: 55px;
    text-transform: initial;
    line-height: 80px;
}
.homepage p {
    margin: 0;
}
.homepage .center{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    font-weight: bold;
}
.logo-container, address {
    position: absolute;
    right: 0;
    bottom: 0;
}
address{
    font-size: 14px;
    max-width: 320px;
}
.bold {
    font-weight: bold;
}

address{
    left: 0;
    right: auto;
}
.logo-container{
    width: 255px;
}
.logo-container h2{
    font-size: 18px;
    text-transform: none;
}
.logo-container img{
    max-width: 153px;
    margin-right:0;
    margin-left: auto;
}

.second-section {
    padding: 190px 90px 90px;
    height: 670px;
    font-family: 'Montserrat', sans-serif;
}

.red-description {
    max-width: 500px;
}

.red-description p {
    font-size: 32px;
    color: #dd4339;
}

.normal-description {
    max-width: 580px;
}

.normal-description p {
    font-size: 20px;
}

.normal-description .bold {
    font-size: 18px;
    margin-bottom: 50px;
}

.founder-section {
    height: 555px;
}

.founder-container {
    width: 40%;
    float: left;
    background-color: #f4f4f2;
    height: 100%;
}

.founder-container > div {
    max-width: 300px;
    margin: 90px 0 91px 225px;
}

.founder-container .circle-founder {
    width: 215px;
    height: 215px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.founder-container h2 {
    color: #dd4339;
}

.founder-container p {
    font-size: 20px;
}

.description-container {
    width: 60%;
    float: left;
    background-color: #ececea;
    height: 100%;
}

.founder-description {
    max-width: 615px;
    margin: 0 auto;
    padding: 190px 0;
    height: 100%;
}

.founder-description p {
    font-size: 18px;
}

.navbar-toggle { cursor: pointer; padding: 10px 35px 16px 0; border: 0; }
.navbar-toggle span, .navbar-toggle span:before, .navbar-toggle span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 5px;
    width: 35px;
    background: white;
    position: absolute;
    display: block;
    content: '';
}
.navbar-toggle span:before {
    top: -10px;
}
.navbar-toggle span:after {
    bottom: -10px;
}

.navbar-toggle span, .navbar-toggle span:before, .navbar-toggle span:after {
    transition: all 500ms ease-in-out;
}
.navbar-toggle.active span {
    background-color: transparent;
}
.navbar-toggle.active span:before, .navbar-toggle.active span:after {
    top: 0;
}
.navbar-toggle.active span:before {
    transform: rotate(45deg);
}
.navbar-toggle.active span:after {
    transform: rotate(-45deg);
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
    border: 0;
    background-color: transparent;
}

.homepage.menu-open:after {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #40403e;
}

.overflow-hidden {
    overflow: hidden;
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
    outline: 0;
    color: #fff;
}

.navbar-nav .lang-en {
    padding-bottom: 0;
    padding-right: 0;
}

.navbar-nav .languages {
    display: inline-block;
}

.navbar-nav .languages .btn {
    background: transparent;
    padding-top: 11px;
    padding-bottom: 0;
    padding-left: 0;
    font-size: 16px;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.navbar-nav .languages .btn a {
    color: #fff;
}

.navbar-nav .languages .btn a:hover {
    text-decoration: none;
    color: #fff;
}

.navbar-nav .languages .btn a:focus {
    outline: 0;
    text-decoration: none;
}

.navbar-nav .languages .btn:focus {
    outline: 0;
    box-shadow: none;
}

.navbar-nav .languages .btn:active {
    box-shadow: none;
}

.navbar-nav .languages i {
    font-size: 26px;
    margin-left: 5px;
}

.navbar-nav .languages .dropdown-menu {
    background: transparent;
    box-shadow: none;
    border: 0;
    margin-left: -35px;
    min-width: 50px;
    padding-top:  0;
    margin-top: -8px;
}


.navbar-nav .languages .dropdown-menu a {
    padding-left: 10px;
    color: #fff;
}

.navbar-nav .languages .dropdown-menu .lang-no {
    padding-left: 12px;
}

.navbar-nav .languages .dropdown-menu a:hover {
    background: transparent;
    color: #dd4339 !important;
}

.display-block-mobile {
    display: none;
}

.red {
    color: #dd4339 !important;
}

.white {
    color: #fff !important;
}

.lang-en {
    position: relative;
}

.drop-toggle .lang-no {
    padding-right: 0;
}

.navbar-nav .contact-border {
    position: relative;
    padding-right: 35px;
}

.navbar-nav .contact-border:after{
    content: "";
    width: 1px;
    height: 14px;
    background: #fff;
    display: inline-block;
    right: 10px;
    position: absolute;
    top: 18px;
}

.navbar>.container-fluid .navbar-brand {
    margin-left: 0;
}

.navbar-default .navbar-brand img {
    margin-top: -30px;
}

.contact-section {
    color: white;
    text-transform: uppercase;
    padding: 90px;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position:center bottom;
    background-attachment:fixed;
}

.contact-section .contact-container {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.contact-section .contact-container h2 {
    text-transform: none;
    font-size: 48px;
    margin-bottom: 70px;
}

.contact-section .contact-container p {
    font-size: 22px;
    text-transform: none;
}

.contact-section .contact-container p:first-of-type {
    margin-bottom: 60px;
}

.contact-section .contact-container a {
    color: #fff;
}

.contact-section .contact-container i {
    margin-right: 30px;
}

.display-none-mobile .lang-en {
    position: relative;
    padding-right: 8px;
}

.display-none-mobile .lang-no {
    padding-left: 8px;
}

.display-none-mobile .lang-en:after{
    content: "";
    width: 1px;
    height: 15px;
    display: inline-block;
    position: absolute;
    background: #fff;
    bottom: 3px;
    right: 0;
}


@media all and (max-width: 1199px) {
    .homepage {
        background-attachment: initial;
    }
    .founder-description {
        padding-left: 20px;
        padding-right: 20px;
    }
    .founder-container > div {
        margin-left: auto;
        margin-right: auto;
        padding: 0 20px;
    }
    .contact-section {
        background-attachment: initial;
    }
}

@media all and (max-width: 767px) {
    .overflow-hidden {
        height: 100%;
        width: 100%;
        position: fixed;
    }
    .navbar-toggle.collapsed,
    .navbar-toggle.active {
        margin-top: 10px;
        margin-right: 0;
    }
    .navbar-default .navbar-brand {
        display: inline-block;
        padding: 0;
    }
    .navbar-default .navbar-nav>li>a {
        line-height: 40px;
    }
    .navbar-default .navbar-brand img {
        width: 150px;
        margin-top: 0;
    }
    .navbar-nav .contact-border:after{
        display: none;
    }
    .navbar-toggle span,
    .navbar-toggle span:before,
    .navbar-toggle span:after {
        height: 4px;
        width: 25px;
    }
    .display-none-mobile {
        display: none !important;
    }
    .display-block-mobile {
        display: block;
        position: absolute;
        bottom: 20%;
    }
    .display-block-mobile ul {
        padding-left: 0;
        list-style-type: none;
    }
    .display-block-mobile ul li {
        padding-right: 8px;
        border-right: 1px solid #fff;
        line-height: .8;
        display: inline-block;
    }
    .display-block-mobile ul li:last-of-type {
        border-right: 0;
        padding-left: 5px;
    }
    .display-block-mobile ul li a {
        font-size: 22px;
        color: #fff;
    }
    .display-block-mobile ul li a:hover {
        text-decoration: none;
        color: #dd4339;
    }
    .display-block-mobile ul li a:focus {
        text-decoration: none;
    }
    .navbar-default .navbar-nav>li>a {
        font-size: 24px;
        line-height: 40px;
    }
    #homepage nav {
        top: 40px;
        height: 100%;
    }
    #homepage .navbar .navbar-header {
        float: none;
        padding-left: 40px;
        padding-right: 40px;
    }
    .container-fluid>.navbar-header,
    .container-fluid>.navbar-collapse {
        margin-right: 0;
        margin-left: 0;
        border: 0;
        box-shadow: none;
    }
    .container-fluid>.navbar-collapse {
        float: left;
        margin-left: 40px;
        height: 100%;
        margin-top: 80px;
        padding: 0;
    }
    #homepage .homepage {
        padding-left: 40px;
    }
    .homepage .center {
        position: static;
        top: 0;
        transform: translateY(0);
        margin-top: 25%;
        font-size: 32px;
        max-width: 550px;
    }
    .second-section {
        padding-top: 120px;
        padding-bottom: 0;
    }
    .founder-section {
        height: initial;
    }
    .normal-description {
        margin-top: 100px;
    }
    address{
        position: static;
        margin-top: 10%;
        font-size: 24px;
        max-width: 550px;
    }
    .logo-container{
        position: static;
        margin-top: 25%;
    }
    .logo-container img{
        margin: 0;
    }
    .homepage h1{
        font-size: 50px;
        max-width: 550px;
    }
    .founder-container {
        text-align: center;
        width:100%;
        float: none;
    }
    .founder-container > div {
        margin-left: auto;
        margin-right: auto;
        padding-top: 60px;
        margin-bottom: 0;
    }
    .founder-container .circle-founder {
        margin: 0 auto;
    }
    .founder-container p {
        margin-bottom: 0;
    }
    .founder-description {
        padding: 40px;
        margin: 0;
        max-width: initial;
    }
    .description-container {
        background-color: #f4f4f2;
        width:100%;
        float: none;
    }
    .second-section {
        height: initial;
    }
    .contact-section {
        padding-left: 40px;
        padding-right: 40px;
    }
    .contact-section .contact-container h2 {
        font-size: 24px;
        margin-bottom: 40px;
    }
    .contact-section .contact-container p:first-of-type {
        margin-bottom: 40px;
    }
    .contact-section .contact-container p {
        font-size: 18px;
    }
}
@media all and (max-width: 480px){
    .homepage{
        padding: 5%;
    }
    .homepage h1{
        font-size: 32px;
        line-height: 50px;
    }
    .homepage .center{
        font-size: 24px;
    }
    #homepage .homepage-description {
        max-width: 260px;
        height: 90%;
    }
    address{
        font-size: 18px;
    }
    .red-description p {
        font-size: 25px;
    }
    .second-section {
        padding-left: 40px;
        padding-right: 40px;
    }
}
