.cookies-banner {
  position: fixed;
  bottom: 0;
  background-color: #E87E6A;
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
  border-top: 4px solid white;

  .cookies-text {
    flex: 1;

    h2 {
      color: white;
    }

    a {
      color: white;
    }
  }

  .cookies-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;

    // TODO
    button {
      width: 150px;
      background-color: white;
      border: none;
      font-size: 16px;
      &:first-of-type {
        color: #dd4339;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.8);
      }
      transition: 0.2s;
    }
  }
  
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}