.legal-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E87E6A;
  background-repeat: no-repeat;
  background-size: auto;
  width: 100%;
  min-height: 100vh;

  .legal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 150px;
    max-width: 1400px;
  }

  .legal-content-container {
    background-color: #f4f4f2;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    justify-content: center;
    padding: 50px;
    margin-bottom: 50px;

    .indent-list {
      margin-left: 40px;
    }

    h4 {
      font-weight: bold;
    }

    a {
      color: #dd4339;
    }
    section {
      margin-bottom: 50px;
    }
  }

  // TODO
  .withdraw-button{
    width: 250px;
    background-color: #dd4339;
    border: none;
    font-size: 16px;
    padding: 10px;
    color: white;
    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
      color: #dd4339;
    }
    transition: 0.2s;
  }


}